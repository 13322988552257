<template>
  <b-container class="about">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </b-container>
</template>

<script>
export default {
  name: "About"
}
</script>
